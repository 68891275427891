/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 109번째 로그에서는 클럽하우스, 토스증권, 페이스북 4분기 실적에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "마이데이터 사업"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20210204183140"
  }, "마이데이터 사업, 금융 새 먹거리 될까 - ZDNet Korea")), "\n"), "\n", React.createElement(_components.h3, null, "애플 디바이스 정보 수집 제한"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/2021020883841"
  }, "페북 약점 건드린 애플…’소송 전쟁’ 치닫나 - 한경닷컴")), "\n"), "\n", React.createElement(_components.h3, null, "페이스북 2020년 4분기 IR"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://investor.fb.com/investor-events/event-details/2021/Facebook-Q4-2020-Earnings-/default.aspx"
  }, "Facebook Q4 2020 Earnings")), "\n"), "\n", React.createElement(_components.h3, null, "토스 증권"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tossinvest.com/"
  }, "토스 증권")), "\n"), "\n", React.createElement(_components.h3, null, "클럽하우스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joinclubhouse.com/"
  }, "Clubhouse: Drop-in audio chat")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.agora.io/en/"
  }, "Agora.io Real-Time Voice and Video Engagement - Agora.io")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
